<template>
</template>
<script>
export default {
    beforeCreate(){
        parent?.window?.danalPPayComplete?.(this.$route.query);
        opener?.window?.danalPPayComplete?.(this.$route.query);
        if(parent || opener) window.close();
        // else window.location.href =  `/gshop/result${window.location.search}`;
    },
}
</script>